/* eslint-disable @kaminrunde/firescout/onclick-needs-handle */
/* eslint-disable @kaminrunde/firescout/jsx-expression-needs-state */
import * as React from 'react'
import * as t from './types'
import StoreList from './StoreList'
import Map from './Map'

type Props = {
  city: string
  context: {
    stores: t.Store[]
  }
}

export default function Storefinder(props: Props) {
  const [activeStore, setActiveStore] = React.useState<t.Store | null>(null)
  const [width, setWidth] = React.useState(0)
  const ref = React.useRef(null)

  React.useEffect(() => {
    // @ts-ignore
    ref.current && setWidth(ref.current.offsetWidth)
  }, [])

  return (
    <div ref={ref} className="Storefinder" style={{ lineHeight: 1.58 }}>
      {ref.current && (
        <Map
          width={width}
          stores={props.context.stores}
          setActiveStore={setActiveStore}
          activeStore={activeStore}
        />
      )}
      <StoreList
        stores={props.context.stores}
        setActiveStore={setActiveStore}
        activeStore={activeStore}
      />
    </div>
  )
}
