// @flow
import * as React from 'react'
import config from 'config'
import { Store } from '../types'
import { fitBounds } from 'google-map-react/utils'

const HOVER_DISTANCE = 30

const getBoundingPoints = (hits: any) =>
  hits.reduce(
    (bounds, hit) => {
      const pos = hit._geoloc
      if (pos.lat > bounds.nw.lat) bounds.nw.lat = pos.lat
      if (pos.lat < bounds.se.lat) bounds.se.lat = pos.lat

      if (pos.lng < bounds.nw.lng) bounds.nw.lng = pos.lng
      if (pos.lng > bounds.se.lng) bounds.se.lng = pos.lng
      return bounds
    },
    {
      nw: { lat: -85, lng: 180 },
      se: { lat: 85, lng: -180 },
    }
  )

const getBoundsConfig = (hits: any, width: any, height: any) => {
  if (hits.length === 1) {
    let clone = { ...hits[0] }
    clone._geoloc = { lat: clone._geoloc.lat + 0.002, lng: clone._geoloc.lng + 0.002 }
    hits.push(clone)
  }
  if (hits.length > 0) {
    let boundingPoints = getBoundingPoints(hits)
    let bounds = fitBounds(boundingPoints, { width, height })
    return bounds
  } else {
    return {}
  }
}

export const useGoogleMapsConfig = ({
  width,
  height,
  stores,
}: {
  width: number
  height: number
  stores: Store[]
}) => {
  const mapOptions = React.useMemo(() => {
    const boundsConfig = getBoundsConfig(stores, width, height)
    return {
      options: {
        mapTypeControl: true,
        streetViewControl: true,
      },
      hoverDistance: HOVER_DISTANCE,
      center: boundsConfig.center,
      zoom: 12,
      bootstrapURLKeys: {
        key: config.modules.googlemaps.apiKey,
      },
      resetBoundsOnResize: true,
    }
  }, [stores, width, height])

  return mapOptions
}
